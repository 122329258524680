import React, { useState, useRef } from "react";
import "../css/navbar.css";

import { FaAngleDown } from "react-icons/fa";
import { FiMenu } from "react-icons/fi";
import pdf from "../multimedia/CV Ricardo Flores.pdf";

const Navbar = () => {
  const [isActive, setIsActive] = useState(false);
  const subMenu = () => setIsActive(!isActive);
  const dropdownRef = useRef(null);

  return (
    <div>
      <div className="navCompu nav">
        <h1 className="nombre">
          <a href="/" className="nombre">
            Ricardo Flores
          </a>
        </h1>
        <ul className="subMenu">
          <li className="listaNav">
            <a className="opcion" href="/">
              Inicio
            </a>
          </li>
          <li
            className="listaNav"
            onMouseEnter={subMenu}
            onMouseLeave={subMenu}
          >
            <a className="opcion">
              <FiMenu /> Mi trabajo <FaAngleDown />
            </a>
            <ul
              ref={dropdownRef}
              className={`dropdownMenu ${isActive ? "active" : "inactive"}`}
            >
              <a className="subopcion" href="/DisenoSonoro">
                <li className="sublistaNav">Diseño Sonoro</li>
              </a>
              <a className="subopcion" href="/Composicion">
                <li className="sublistaNav">Composición</li>
              </a>
              {/* <a className="subopcion" href="/Mix-Master">
                <li className="sublistaNav">Mix/Master</li>
              </a> */}
              <a className="subopcion" href="/Proximamente">
                <li className="sublistaNav">Mix/Master</li>
              </a>
            </ul>
          </li>
          <li className="listaNav">
            <a className="opcion" href="/SobreMi">
              Sobre mi
            </a>
          </li>
          <li className="listaNav">
            <a className="opcion" href="/Contacto">
              Contacto
            </a>
          </li>
          <li className="listaNav">
            <a
              className="opcion"
              href={pdf}
              target="_blank"
              rel="noopener noreferrer"
            >
              Mi CV
            </a>
          </li>
          <li className="listaNav">
            <a className="opcion" href="/Proximamente">
              Estudios Ketzal
            </a>
          </li>
        </ul>
      </div>
      <div className="navCel nav">
        <h1 className="nombre">
          <a href="/" className="nombre">
            Ricardo Flores
          </a>
        </h1>
        <ul className="subMenu">
          <li className="listaNav" onClick={subMenu}>
            <a className="opcion">
              <FiMenu />
              <FaAngleDown />
            </a>

            <ul
              ref={dropdownRef}
              className={`dropdownMenu ${isActive ? "active" : "inactive"}`}
            >
              <a className="subopcion" href="/">
                <li className="sublistaNav">Inicio</li>
              </a>
              <a className="subopcion" href="/DisenoSonoro">
                <li className="sublistaNav">Diseño Sonoro</li>
              </a>
              <a className="subopcion" href="/Mix-Master">
                <li className="sublistaNav">Mix/Master</li>
              </a>
              <a className="subopcion" href="/Composicion">
                <li className="sublistaNav">Composición</li>
              </a>
              <a className="subopcion" href="/SobreMi">
                <li className="sublistaNav">Sobre mi</li>
              </a>
              <a className="subopcion" href="/Contacto">
                <li className="sublistaNav">Contacto</li>
              </a>
              <a
                className="subopcion"
                href={pdf}
                target="_blank"
                rel="noopener noreferrer"
              >
                <li className="sublistaNav">Mi CV</li>
              </a>
              <a className="subopcion" href="/SobreMi">
                <li className="sublistaNav">Estudios Ketzal</li>
              </a>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
