import React, { useState } from "react";
import "../css/diseno.css";

import Player from "react-player";

const DiseñoSonoro = () => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div>
      <div className="navCompu">
        <section className="bannerHomeD" id="banner">
          <div className="textoBanner">
            {/* <div className="filtro"></div> */}
            <h1 className="nombreHome">DISEÑO SONORO</h1>
          </div>
        </section>
        <section className="videosDiseno">
          <div className="filtro"></div>
          <div className="contenidoDiseno">
            <div className="filaDiseno">
              <div className="textoDiseno apartadoDiseno aptDIzq">
                <h3 className="tituloVidDiseno">Gotham knigths trailer</h3>
                <p className="descDiseno">
                  Diseñador de ambientes, foleys y sound fx en trabajo en
                  equipo.
                </p>
              </div>
              <div className="vidDiseno apartadoDiseno">
                <Player
                  className="playerVidDiseno"
                  controls
                  url="https://youtu.be/YpC6BA4pQ1k"
                  config={{
                    file: {
                      attributes: {
                        controlsList: "nodownload",
                        // onContextMenu: (e) => e.preventDefault(),
                        //impide click derecho
                      },
                    },
                  }}
                />
              </div>
            </div>
            <div className="filaDiseno">
              <div className="vidDiseno apartadoDiseno">
                <Player
                  className="playerVidDiseno"
                  controls
                  url="https://youtu.be/shqPv_HySj4"
                  config={{
                    file: {
                      attributes: {
                        controlsList: "nodownload",
                        // onContextMenu: (e) => e.preventDefault(),
                        //impide click derecho
                      },
                    },
                  }}
                />
              </div>
              <div className="textoDiseno apartadoDiseno aptDDer">
                <h3 className="tituloVidDiseno">Stray Teaser trailer</h3>
                <p className="descDiseno">
                  Grabación, edición y creación de todas las capas de diseño
                  sonoro del trailer y mezcla del mismo.
                </p>
              </div>
            </div>
            <div className="filaDiseno">
              <div className="textoDiseno apartadoDiseno aptDIzq">
                <h3 className="tituloVidDiseno">
                  Midle Earth Shadow of war trailer
                </h3>
                <p className="descDiseno">
                  Editor de música para el proyecto e Ingeniero de mezcla.
                </p>
              </div>
              <div className="vidDiseno apartadoDiseno">
                <Player
                  className="playerVidDiseno"
                  controls
                  url="https://youtu.be/9wXPe5Gz9jw"
                  config={{
                    file: {
                      attributes: {
                        controlsList: "nodownload",
                        // onContextMenu: (e) => e.preventDefault(),
                        //impide click derecho
                      },
                    },
                  }}
                />
              </div>
            </div>
            <div className="filaDiseno">
              <div className="vidDiseno apartadoDiseno">
                <Player
                  className="playerVidDiseno"
                  controls
                  url="https://youtu.be/DZeuG3XdypI"
                  config={{
                    file: {
                      attributes: {
                        controlsList: "nodownload",
                        // onContextMenu: (e) => e.preventDefault(),
                        //impide click derecho
                      },
                    },
                  }}
                />
              </div>
              <div className="textoDiseno apartadoDiseno aptDDer">
                <h3 className="tituloVidDiseno">
                  Final Fantasy VII Remaster trailer
                </h3>
                <p className="descDiseno">
                  Editor de música para el proyecto e Ingeniero de mezcla para
                  trailer en formato binaural.
                </p>
              </div>
            </div>
            <div className="filaDiseno">
              <div className="textoDiseno apartadoDiseno aptDIzq">
                <h3 className="tituloVidDiseno">Video publicitario Michelin</h3>
                <p className="descDiseno">
                  Grabación, edición y creación de todas las capas de diseño
                  sonoro del trailer, creación de speech y voz en off, ingeniero
                  de mezcla.
                </p>
              </div>
              <div className="vidDiseno apartadoDiseno">
                <Player
                  className="playerVidDiseno"
                  controls
                  url="https://youtu.be/Nfzcdc947w4"
                  config={{
                    file: {
                      attributes: {
                        controlsList: "nodownload",
                        // onContextMenu: (e) => e.preventDefault(),
                        //impide click derecho
                      },
                    },
                  }}
                />
              </div>
            </div>
            <div className="filaDiseno">
              <div className="vidDiseno apartadoDiseno">
                <Player
                  className="playerVidDiseno"
                  controls
                  url="https://youtu.be/VmenZc1_eU0"
                  config={{
                    file: {
                      attributes: {
                        controlsList: "nodownload",
                        // onContextMenu: (e) => e.preventDefault(),
                        //impide click derecho
                      },
                    },
                  }}
                />
              </div>
              <div className="textoDiseno apartadoDiseno aptDDer">
                <h3 className="tituloVidDiseno">
                  Fragmento binaural interestelar{" "}
                </h3>
                <p className="descDiseno">
                  Creación de capas de diseño sonoro e ingeniero de mezcla en
                  formato binaural.
                </p>
              </div>
            </div>
          </div>
        </section>
        {/*<div className="contenidoDiseno2">
            <div className="filaDiseno2">
              <div className="vidDiseno2 apartadoDiseno2">
                <Player
                  className="playerVidDiseno2"
                  controls
                  url="https://www.youtube.com/watch?v=3_mmZlM8kNo"
                  config={{
                    file: {
                      attributes: {
                        controlsList: "nodownload",
                        // onContextMenu: (e) => e.preventDefault(),
                        //impide click derecho
                      },
                    },
                  }}
                />
              </div>
            </div>
          </div> 
        <div className="textoDiseno2 apartadoDiseno2 aptDIzq2">
          <h3 className="tituloVidDisno2">Titulo video</h3>
          <p className="descDiseno2">
            descripcion absurdamente larga del video solo de placeholder hasta
            que este la descripcion de verdad
          </p>
        </div>
        <div className="filaDiseno2">
          <div className="vidDiseno2 apartadoDiseno2">
            <Player
              className="playerVidDiseno2"
              controls
              url="https://www.youtube.com/watch?v=3_mmZlM8kNo"
              config={{
                file: {
                  attributes: {
                    controlsList: "nodownload",
                    // onContextMenu: (e) => e.preventDefault(),
                    //impide click derecho
                  },
                },
              }}
            />
          </div>
          <div className="textoDiseno2 apartadoDiseno2 aptDDer2">
            <h3 className="tituloVidDisno2">Titulo video</h3>
            <p className="descDiseno2">
              descripcion absurdamente larga del video solo de placeholder hasta
              que este la descripcion de verdad
            </p>
          </div>
        </div>
        <div className="filaDiseno2">
          <div className="vidDiseno2 apartadoDiseno2">
            <Player
              className="playerVidDiseno2"
              controls
              url="https://www.youtube.com/watch?v=3_mmZlM8kNo"
              config={{
                file: {
                  attributes: {
                    controlsList: "nodownload",
                    // onContextMenu: (e) => e.preventDefault(),
                    //impide click derecho
                  },
                },
              }}
            />
          </div>
          <div className="textoDiseno2 apartadoDiseno2 aptDDer2">
            <h3 className="tituloVidDisno2">Titulo video</h3>
            <p className="descDiseno2">
              descripcion absurdamente larga del video solo de placeholder hasta
              que este la descripcion de verdad
            </p>
          </div>
        </div> */}
      </div>
      <div className="navCel">
        <section className="bannerHomeD" id="banner">
          <div className="textoBanner">
            {/* <div className="filtro"></div> */}
            <h1 className="nombreHome">DISEÑO SONORO</h1>
          </div>
        </section>
        <section className="videosDiseno">
          <div className="filtro"></div>
          <div className="contenidoDiseno">
            <div className="filaDiseno">
              <div className="textoDiseno apartadoDiseno aptD">
                <h3 className="tituloVidDiseno">Gotham knigths trailer</h3>
                <p className="descDiseno">
                  Diseñador de ambientes, foleys y sound fx en trabajo en
                  equipo.
                </p>
              </div>
              <div className="vidDiseno apartadoDiseno">
                <Player
                  className="playerVidDiseno"
                  controls
                  url="https://youtu.be/YpC6BA4pQ1k"
                  config={{
                    file: {
                      attributes: {
                        controlsList: "nodownload",
                        // onContextMenu: (e) => e.preventDefault(),
                        //impide click derecho
                      },
                    },
                  }}
                />
              </div>
            </div>
            <div className="filaDiseno">
              <div className="textoDiseno apartadoDiseno aptD">
                <h3 className="tituloVidDiseno">Stray Teaser trailer</h3>
                <p className="descDiseno">
                  Grabación, edición y creación de todas las capas de diseño
                  sonoro del trailer y mezcla del mismo.
                </p>
              </div>
              <div className="vidDiseno apartadoDiseno">
                <Player
                  className="playerVidDiseno"
                  controls
                  url="https://youtu.be/shqPv_HySj4"
                  config={{
                    file: {
                      attributes: {
                        controlsList: "nodownload",
                        // onContextMenu: (e) => e.preventDefault(),
                        //impide click derecho
                      },
                    },
                  }}
                />
              </div>
            </div>
            <div className="filaDiseno">
              <div className="textoDiseno apartadoDiseno aptD">
                <h3 className="tituloVidDiseno">
                  Midle Earth Shadow of war trailer
                </h3>
                <p className="descDiseno">
                  Editor de música para el proyecto e Ingeniero de mezcla.
                </p>
              </div>
              <div className="vidDiseno apartadoDiseno">
                <Player
                  className="playerVidDiseno"
                  controls
                  url="https://youtu.be/9wXPe5Gz9jw"
                  config={{
                    file: {
                      attributes: {
                        controlsList: "nodownload",
                        // onContextMenu: (e) => e.preventDefault(),
                        //impide click derecho
                      },
                    },
                  }}
                />
              </div>
            </div>
            <div className="filaDiseno">
              <div className="textoDiseno apartadoDiseno aptD">
                <h3 className="tituloVidDiseno">
                  Final Fantasy VII Remaster trailer
                </h3>
                <p className="descDiseno">
                  Editor de música para el proyecto e Ingeniero de mezcla para
                  trailer en formato binaural.
                </p>
              </div>
              <div className="vidDiseno apartadoDiseno">
                <Player
                  className="playerVidDiseno"
                  controls
                  url="https://youtu.be/DZeuG3XdypI"
                  config={{
                    file: {
                      attributes: {
                        controlsList: "nodownload",
                        // onContextMenu: (e) => e.preventDefault(),
                        //impide click derecho
                      },
                    },
                  }}
                />
              </div>
            </div>
            <div className="filaDiseno">
              <div className="textoDiseno apartadoDiseno aptD">
                <h3 className="tituloVidDiseno">Video publicitario Michelin</h3>
                <p className="descDiseno">
                  Grabación, edición y creación de todas las capas de diseño
                  sonoro del trailer, creación de speech y voz en off, ingeniero
                  de mezcla.
                </p>
              </div>
              <div className="vidDiseno apartadoDiseno">
                <Player
                  className="playerVidDiseno"
                  controls
                  url="https://youtu.be/Nfzcdc947w4"
                  config={{
                    file: {
                      attributes: {
                        controlsList: "nodownload",
                        // onContextMenu: (e) => e.preventDefault(),
                        //impide click derecho
                      },
                    },
                  }}
                />
              </div>
            </div>
            <div className="filaDiseno">
              <div className="textoDiseno apartadoDiseno aptD">
                <h3 className="tituloVidDiseno">
                  Fragmento binaural interestelar{" "}
                </h3>
                <p className="descDiseno">
                  Creación de capas de diseño sonoro e ingeniero de mezcla en
                  formato binaural.
                </p>
              </div>
              <div className="vidDiseno apartadoDiseno">
                <Player
                  className="playerVidDiseno"
                  controls
                  url="https://youtu.be/VmenZc1_eU0"
                  config={{
                    file: {
                      attributes: {
                        controlsList: "nodownload",
                        // onContextMenu: (e) => e.preventDefault(),
                        //impide click derecho
                      },
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default DiseñoSonoro;
