import React from "react";
import "../css/homepage.css";
import "../css/aboutme.css";
import foto from "../multimedia/sobreMi.JPG";

import { BsWhatsapp, BsPeople } from "react-icons/bs";
import Player from "react-player";
import { TiSocialLinkedinCircular } from "react-icons/ti";

const AboutMe = () => {
  return (
    <div className="AboutMe">
      <div className="mail-container">
        <div className="mail-wrapper">
          <div className="mail-content">
            <div className="profile-wrapper">
              <div className="profile-header profile-same">
                <div className="profile-avatar">
                  <img src={foto} alt=""></img>
                </div>
                <h1>RICARDO FLORES</h1>
                <h2>Audio Engineer</h2>
              </div>
              <div className="profile-bio profile-same">
                <p className="short-description">
                  Cuando las palabras dejan de ser suficientes para expresarme,
                  sé que tengo a la música de mi lado. Desde siempre la música
                  ha formado una parte muy importante de mi vida, me apasiona el
                  poder transmitir emociones y sensaciones a través del arte
                  sonoro haciendo uso de la tecnología para dejar volar mi
                  imaginación y dejando como única limitante mi creatividad.
                  Considero que cada proyecto cuenta con la esencia de las
                  personas que trabajan en él, es por eso que me interesa
                  integrarme a profundidad en cada proyecto del que formo parte,
                  para impulsarlo al máximo.
                  <br />
                  <br />
                  Ingeniero en Producción Musical Digital por el Tecnológico de
                  Monterrey.
                </p>
                <div id="profile-bio-wrapper">
                  <div id="show-more-icon" className="no-select">
                    <i
                      className="fa fa-chevron-circle-up"
                      aria-hidden="true"
                    ></i>
                  </div>
                  <div className="social-list">
                    <ul>
                      <li className="bio-item paper-button"></li>
                      <li className="bio-item paper-button">
                        <a
                          href="https://wa.me/525541900701"
                          title="WhatsApp"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <BsWhatsapp
                            size={20}
                            color={"green"}
                            className="iconoAboutMe"
                          />
                        </a>
                      </li>
                      <li className="bio-item paper-button">
                        <a
                          href="https://www.linkedin.com/in/ricardo-flores-reyes-02b872219"
                          title="Linkedin"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <TiSocialLinkedinCircular
                            size={23}
                            color={"blue"}
                            className="iconoAboutMe"
                          />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="divVideoAbout">
              <Player
                light="https://firebasestorage.googleapis.com/v0/b/luis-portfolio-d518a.appspot.com/o/Captura.PNG?alt=media&token=e7fcd541-48c8-4ba2-b986-626d88434b5d"
                className="videoYTAbout"
                controls
                playing
                url="https://firebasestorage.googleapis.com/v0/b/luis-portfolio-d518a.appspot.com/o/Video%20CV%20Ricardo%20Flores.m4v?alt=media&token=0dee9ccb-dcdd-4cac-9997-f72e091da142"
                config={{
                  file: {
                    attributes: {
                      controlsList: "nodownload",
                      onContextMenu: (e) => e.preventDefault(),
                      //impide click derecho
                    },
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-ball-fill"></div>
    </div>
  );
};

export default AboutMe;
