import React, { useState } from "react";
import Player from "react-player";
import "../css/homepage.css";

const Homepage = () => {
  return (
    <div>
      <div>
        <div>
          {/* <section className="bannerHome" id="banner">
          <div className="textoBanner">
            <div className="filtro"></div>
            <div className="nombreBanner">
              <h1 className="nombreHome">RICARDO FLORES.</h1>
            </div>
            <div className="carreraBanner">
              <h1 className="carreraHome">
                sgdfgagaggfgdgedgedrgdfgdaklnakljsdnkladnlakdnlak <br />
                afafafafafafasfasfafaf
              </h1>
            </div>
          </div>
        </section>
        <section className="trabajoHome" id="MiTrabajo">
          <div className="MiTrabajo">
            <h1 className="textoTrabajo">MI TRABAJO</h1>
          </div>
          <section className="fotosTrabajo">
            <div className="apartadoFoto apt1">
              <div>
                <a href="/DisenoSonoro">
                  <img className="fotoHome" src={sora} />
                </a>
                <h3 className="nombreTrabajo">SOUND DESIGN</h3>
                <p className="descTrabajo">
                  ogsihosaghgn ajigsadkgj ks fjalk jslkadja djaljal jj jal
                </p>
              </div>
            </div>
            <div className="apartadoFoto apt2">
              <div>
                <a href="/Mix-Master">
                  <img className="fotoHome" src={shin} />
                </a>
                <h3 className="nombreTrabajo">MIX AND MASTER ENGINEER</h3>
                <p className="descTrabajo">
                  ogsihosaghgn ajigsadkgj ks fjalk jslkadj afsaf sa ad a a da da
                  da djaljal jj jal
                </p>
              </div>
            </div>
            <div className="apartadoFoto apt3">
              <div>
                <a href="/ArregloMusical">
                  <img className="fotoHome" src={shantae} />
                </a>
                <h3 className="nombreTrabajo">MUSICAL ARRANGEMENT</h3>
                <p className="descTrabajo">
                  ogsihosaghgn ajigsadkgj ks fjalk jslkadja djaljal jj jal
                </p>
              </div>
            </div>
          </section>
        </section>
        <section className="sobreMi" id="SobreMi">
          <div className="datosSobreMi">
            <div className="fondoSobreMi"></div>
            <div className="miImagen">
              <img className="imagenYo" src={chango} />
            </div>
            <div className="miNombre">
              <h2 className="miNom">R I C A R D O F L O R E S</h2>
            </div>
            <div className="miDescripcion">
              <p className="miDesc">
                <strong>
                  adsakjdha ksjklasd jlkad lkajd akldj klsj kadj a jkjdaksjd
                  kajd sajdlajdlksaj a as disaj lkajsk ajkld jsjdsakdj
                  klajdakldj kaljd lkajd akldjakjd akljd kj dkjd kaljd kad j
                </strong>
              </p>
            </div>
          </div>
        </section> */}
          {/* <section className="CV">
          <div className="CVtexto">
            <div className="textoCV">
              <h3 className="shareCV">
                Si estas interesado en saber más de mi, te comparto mi CV:
              </h3>
            </div>
            <div className="CVboton">
              <a
                href={pdf}
                target="_blank"
                rel="noopener noreferrer"
                className="CVbtn"
              >
                RICFLO2021_CV
              </a>
            </div>
          </div>
        </section> */}
        </div>
        <section className="videoHome">
          <div className="textoHomepage">
            <h1 className="nombreHomepage">Ricardo Flores</h1>
            <h3 className="descHomepage">
              COMPOSITOR • PRODUCTOR • DISEÑADOR SONORO
            </h3>
          </div>
          <div className="vidWidth">
            <Player
              light="https://firebasestorage.googleapis.com/v0/b/luis-portfolio-d518a.appspot.com/o/Captura.PNG?alt=media&token=e7fcd541-48c8-4ba2-b986-626d88434b5d"
              className="videoYTHome"
              controls
              playing
              url="https://www.youtube.com/watch?v=wY1HUwfACIM"
              config={{
                file: {
                  attributes: {
                    controlsList: "nodownload",
                    onContextMenu: (e) => e.preventDefault(),
                    //impide click derecho
                  },
                },
              }}
            />
          </div>
        </section>
        {/* <WavePlayer
          audioUrl={audio}
          hideImage
          waveStyles={{
            cursorWidth: 1,
            progressColor: "#ee3ec9",
            responsive: true,
            waveColor: "#121640",
            cursorColor: "transparent",
            barWidth: 0,
          }}
        /> */}
      </div>
    </div>
  );
};

export default Homepage;
