import React from "react";
import "../css/prox.css";
const Prox = () => {
  return (
    <div>
      <div>
        <section className="bannerProx" id="banner">
          <div className="textoBannerProx">
            <h1 className="Prox">Próximamente</h1>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Prox;
