import React, { useMemo, useCallback, useRef, useState } from "react";
import { WaveSurfer, WaveForm } from "wavesurfer-react";
import CursorPlugin from "wavesurfer.js/dist/plugin/wavesurfer.cursor.min";
import "../css/arreglo.css";
import { FiPause } from "react-icons/fi";
import { FiPlay } from "react-icons/fi";
import { FiVolume2, FiVolume1, FiVolume } from "react-icons/fi";

import intro from "../multimedia/Intro videojuego.wav";
import juego from "../multimedia/Seventh note is not always a demon concept.pdf";
import juegoIMG from "../multimedia/juegoIMG.PNG";
import Historia from "../multimedia/Historia.wav";
import hist from "../multimedia/Historia.jpeg";
import our from "../multimedia/Our Song.wav";
import abrazo from "../multimedia/Abrazo color cielo rough mix.wav";
import nombres from "../multimedia/Los nombres que fuimos .wav";

import Player from "react-player";

const ArregloMusical = () => {
  const plugins = useMemo(() => {
    return [{ plugin: CursorPlugin }].filter(Boolean);
  }, []);

  //Wave 1
  const [isPlaying1, setIsPlaying1] = useState(false);
  const [volume1, setVolume1] = useState(1);

  const waveSurferRef = useRef();
  const handleWSMount = useCallback((waveSurfer) => {
    waveSurferRef.current = waveSurfer;
    if (waveSurferRef.current) {
      if (waveSurferRef.current) {
        waveSurferRef.current.load(intro);
        waveSurferRef.current.setVolume(1);
      }
    }
  });
  const play = () => {
    setIsPlaying1(!isPlaying1);
    waveSurferRef.current.playPause();
  };

  //Wave 2
  const [isPlaying2, setIsPlaying2] = useState(false);
  const [volume2, setVolume2] = useState(1);

  const waveSurferRef2 = useRef();
  const handleWSMount2 = useCallback((waveSurfer) => {
    waveSurferRef2.current = waveSurfer;
    if (waveSurferRef2.current) {
      if (waveSurferRef2.current) {
        waveSurferRef2.current.load(Historia);
        waveSurferRef2.current.setVolume(1);
      }
    }
  });
  const play2 = () => {
    setIsPlaying2(!isPlaying2);
    waveSurferRef2.current.playPause();
  };

  //Wave 3
  const [isPlaying3, setIsPlaying3] = useState(false);
  const [volume3, setVolume3] = useState(1);

  const waveSurferRef3 = useRef();
  const handleWSMount3 = useCallback((waveSurfer) => {
    waveSurferRef3.current = waveSurfer;
    if (waveSurferRef3.current) {
      if (waveSurferRef3.current) {
        waveSurferRef3.current.load(our);
        waveSurferRef3.current.setVolume(1);
      }
    }
  });
  const play3 = () => {
    setIsPlaying3(!isPlaying3);
    waveSurferRef3.current.playPause();
  };

  //Wave 4
  const [isPlaying4, setIsPlaying4] = useState(false);
  const [volume4, setVolume4] = useState(1);

  const waveSurferRef4 = useRef();
  const handleWSMount4 = useCallback((waveSurfer) => {
    waveSurferRef4.current = waveSurfer;
    if (waveSurferRef4.current) {
      if (waveSurferRef4.current) {
        waveSurferRef4.current.load(abrazo);
        waveSurferRef4.current.setVolume(1);
      }
    }
  });
  const play4 = () => {
    setIsPlaying4(!isPlaying4);
    waveSurferRef4.current.playPause();
  };

  //Wave 5
  const [isPlaying5, setIsPlaying5] = useState(false);
  const [volume5, setVolume5] = useState(1);

  const waveSurferRef5 = useRef();
  const handleWSMount5 = useCallback((waveSurfer) => {
    waveSurferRef5.current = waveSurfer;
    if (waveSurferRef5.current) {
      if (waveSurferRef5.current) {
        waveSurferRef5.current.load(nombres);
        waveSurferRef5.current.setVolume(1);
      }
    }
  });
  const play5 = () => {
    setIsPlaying5(!isPlaying5);
    waveSurferRef5.current.playPause();
  };

  return (
    <div>
      <div>
        <section className="bannerHomeC" id="banner">
          <div className="textoBanner">
            {/* <div className="filtro"></div> */}
            <h1 className="nombreHome">Composición</h1>
          </div>
        </section>
        <section className="audiosComposicion">
          <div className="filtro"></div>
          <div className="contenidoComposicion">
            <h3 className="tipoComposicion">Abrazo color cielo</h3>
            <div className="waveComp">
              <WaveSurfer plugins={plugins} onMount={handleWSMount4}>
                <WaveForm id="waveform4"></WaveForm>
              </WaveSurfer>
              <div className="btnsWaveform">
                <div className="ComposicionMasterIcons">
                  <a onClick={play4} className="musicPlaying">
                    {isPlaying4 === false ? <FiPlay /> : <FiPause />}
                  </a>
                </div>
                <div className="ComposicionMasterIcons2">
                  <span className="volumeIcon">
                    {volume4 >= 0.6 ? (
                      <FiVolume2 />
                    ) : volume4 > 0 ? (
                      <FiVolume1 />
                    ) : (
                      <FiVolume />
                    )}
                  </span>
                  <input
                    className="volumeInputComp"
                    type="range"
                    min={0}
                    max={1}
                    step={0.02}
                    value={volume4}
                    onChange={(e) => {
                      setVolume4(e.target.valueAsNumber);
                      waveSurferRef4.current.setVolume(volume4);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="abajoComposicion">
              <div className="textoComposicion2">
                <span className="descComposicion">
                  Perder a un ser querido es algo complicado, vertiginoso y
                  doloroso. Abrazo color cielo es un viaje catártico personal en
                  el cual a través de la música abordo la ausencia de mi padre.
                  Letra y música originales.
                </span>
              </div>
            </div>
          </div>

          <div className="contenidoComposicion">
            <h3 className="tipoComposicion">Los nombres que fuimos</h3>
            <div className="waveComp">
              <WaveSurfer plugins={plugins} onMount={handleWSMount5}>
                <WaveForm id="waveform5"></WaveForm>
              </WaveSurfer>
              <div className="btnsWaveform">
                <div className="ComposicionMasterIcons">
                  <a onClick={play5} className="musicPlaying">
                    {isPlaying5 === false ? <FiPlay /> : <FiPause />}
                  </a>
                </div>
                <div className="ComposicionMasterIcons2">
                  <span className="volumeIcon">
                    {volume5 >= 0.6 ? (
                      <FiVolume2 />
                    ) : volume5 > 0 ? (
                      <FiVolume1 />
                    ) : (
                      <FiVolume />
                    )}
                  </span>
                  <input
                    className="volumeInputComp"
                    type="range"
                    min={0}
                    max={1}
                    step={0.02}
                    value={volume5}
                    onChange={(e) => {
                      setVolume5(e.target.valueAsNumber);
                      waveSurferRef5.current.setVolume(volume5);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="abajoComposicion">
              <div className="textoComposicion2">
                <span className="descComposicion">
                  Pieza original, ¿qué pasa cuando nos alejamos de una persona
                  con la que compartiamos gran parte de nuestra vida? Nos quedan
                  las memorias, las alegrias pero todas ellas quedan manchadas
                  con tintes de notalgia, con una necesidad de regresar al ayer,
                  de recordar a aquellos que fuimos.
                </span>
              </div>
            </div>
          </div>

          <div className="contenidoComposicion">
            <h3 className="tipoComposicion">
              Creación de una historia auditiva
            </h3>
            <div className="waveComp">
              <WaveSurfer plugins={plugins} onMount={handleWSMount2}>
                <WaveForm id="waveform2"></WaveForm>
              </WaveSurfer>
              <div className="btnsWaveform">
                <div className="ComposicionMasterIcons">
                  <a onClick={play2} className="musicPlaying">
                    {isPlaying2 === false ? <FiPlay /> : <FiPause />}
                  </a>
                </div>
                <div className="ComposicionMasterIcons2">
                  <span className="volumeIcon">
                    {volume2 >= 0.6 ? (
                      <FiVolume2 />
                    ) : volume2 > 0 ? (
                      <FiVolume1 />
                    ) : (
                      <FiVolume />
                    )}
                  </span>
                  <input
                    className="volumeInputComp"
                    type="range"
                    min={0}
                    max={1}
                    step={0.02}
                    value={volume2}
                    onChange={(e) => {
                      setVolume2(e.target.valueAsNumber);
                      waveSurferRef2.current.setVolume(volume2);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="abajoComposicion">
              <div className="textoComposicion">
                <span className="descComposicion">
                  Para este proyecto tomando como base una obra de arte creé las
                  distintas capas sonoras para contar una historia, a partir de
                  la pintura compuse la musica y creé el diseño sonoro para la
                  historia.
                </span>
              </div>
              <div className="añadidoComposicion">
                <a
                  className="juegoPDF"
                  href={juego}
                  rel="noreferrer noopener"
                  target="_blank"
                >
                  <img src={hist} className="imgPDF" />
                </a>
              </div>
            </div>
          </div>

          <div className="filaComposicion CompCompu">
            <div className="textComposicion apartadoComposicion aptDIzq">
              <h3 className="tituloVidComposicion">Gotham knigths trailer</h3>
              <p className="descComp">
                Compositor de la banda sonora. Proyecto realizado con fines
                académicos
              </p>
            </div>
            <div className="vidComposicion apartadoComposicion">
              <Player
                className="playerVidComposicion"
                controls
                url="https://youtu.be/YpC6BA4pQ1k"
                config={{
                  file: {
                    attributes: {
                      controlsList: "nodownload",
                      // onContextMenu: (e) => e.preventDefault(),
                      //impide click derecho
                    },
                  },
                }}
              />
            </div>
          </div>
          <div className="filaComposicion CompCompu">
            <div className="vidComposicion apartadoComposicion">
              <Player
                className="playerVidComposicion"
                controls
                url="https://youtu.be/9UUuWNbteHo"
                config={{
                  file: {
                    attributes: {
                      controlsList: "nodownload",
                      // onContextMenu: (e) => e.preventDefault(),
                      //impide click derecho
                    },
                  },
                }}
              />
            </div>
            <div className="textComposicion apartadoComposicion aptDDer">
              <h3 className="tituloVidComposicion">
                Watermelon animated short{" "}
              </h3>
              <p className="descComp">
                Compositor de la banda sonora. Proyecto realizado con fines
                académicos
              </p>
            </div>
          </div>

          <div className="filaComposicion CompCel">
            <div className="textComposicion apartadoComposicion2 aptD2">
              <h3 className="tituloVidComposicion">Gotham knigths trailer</h3>
              <p className="descComposicion2">
                Diseñador de ambientes, foleys y sound fx en trabajo en equipo.
              </p>
            </div>
            <div className="vidComposicion apartadoComposicion2">
              <Player
                className="playerVidComposicion"
                controls
                url="https://youtu.be/YpC6BA4pQ1k"
                config={{
                  file: {
                    attributes: {
                      controlsList: "nodownload",
                      // onContextMenu: (e) => e.preventDefault(),
                      //impide click derecho
                    },
                  },
                }}
              />
            </div>
          </div>
          <div className="filaComposicion CompCel">
            <div className="textComposicion apartadoComposicion2 aptD2">
              <h3 className="tituloVidComposicion">
                Watermelon animated short
              </h3>
              <p className="descComposicion2">
                Compositor de la banda sonora. Proyecto realizado con fines
                académicos.
              </p>
            </div>
            <div className="vidComposicion apartadoComposicion2">
              <Player
                className="playerVidComposicion"
                controls
                url="https://youtu.be/9UUuWNbteHo"
                config={{
                  file: {
                    attributes: {
                      controlsList: "nodownload",
                      // onContextMenu: (e) => e.preventDefault(),
                      //impide click derecho
                    },
                  },
                }}
              />
            </div>
          </div>

          <div className="contenidoComposicion">
            <h3 className="tipoComposicion">Our Song</h3>
            <div className="waveComp">
              <WaveSurfer plugins={plugins} onMount={handleWSMount3}>
                <WaveForm id="waveform3"></WaveForm>
              </WaveSurfer>
              <div className="btnsWaveform">
                <div className="ComposicionMasterIcons">
                  <a onClick={play3} className="musicPlaying">
                    {isPlaying3 === false ? <FiPlay /> : <FiPause />}
                  </a>
                </div>
                <div className="ComposicionMasterIcons2">
                  <span className="volumeIcon">
                    {volume3 >= 0.6 ? (
                      <FiVolume2 />
                    ) : volume3 > 0 ? (
                      <FiVolume1 />
                    ) : (
                      <FiVolume />
                    )}
                  </span>
                  <input
                    className="volumeInputComp"
                    type="range"
                    min={0}
                    max={1}
                    step={0.02}
                    value={volume3}
                    onChange={(e) => {
                      setVolume3(e.target.valueAsNumber);
                      waveSurferRef3.current.setVolume(volume3);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="abajoComposicion">
              <div className="textoComposicion2">
                <span className="descComposicion">
                  Con la inspiración de las canciones románticas de los 80s se
                  compusó esta balada romántica. El piano y la armonía de la
                  canción fueron compuestos por mi y la letra a su vez fue
                  interpretada por un servidor.
                </span>
              </div>
            </div>
          </div>

          <div className="contenidoComposicion">
            <h3 className="tipoComposicion">
              Intro "Seventh note is not always a demon”
            </h3>
            <div className="waveComp">
              <WaveSurfer plugins={plugins} onMount={handleWSMount}>
                <WaveForm id="waveform1"></WaveForm>
              </WaveSurfer>
              <div className="btnsWaveform">
                <div className="ComposicionMasterIcons">
                  <a onClick={play} className="musicPlaying">
                    {isPlaying1 === false ? <FiPlay /> : <FiPause />}
                  </a>
                </div>
                <div className="ComposicionMasterIcons2">
                  <span className="volumeIcon">
                    {volume1 >= 0.6 ? (
                      <FiVolume2 />
                    ) : volume1 > 0 ? (
                      <FiVolume1 />
                    ) : (
                      <FiVolume />
                    )}
                  </span>
                  <input
                    className="volumeInputComp"
                    type="range"
                    min={0}
                    max={1}
                    step={0.02}
                    value={volume1}
                    onChange={(e) => {
                      setVolume1(e.target.valueAsNumber);
                      waveSurferRef.current.setVolume(volume1);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="abajoComposicion">
              <div className="textoComposicion">
                <span className="descComposicion">
                  A partir de un concepto de un videojuego RPG y tomando la
                  teoría musical como temática compuse este tema musical con su
                  versión en mayor y menor dado a que los personajes a escoger
                  eran representaciones de tonalidades mayores y menores.
                </span>
              </div>
              <div className="añadidoComposicion">
                <a
                  className="juegoPDF"
                  href={juego}
                  rel="noreferrer noopener"
                  target="_blank"
                >
                  <img src={juegoIMG} className="imgPDF" />
                  <p className="p">Contexto</p>
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ArregloMusical;
